import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL, headers } from "../../config/config";
import { toast } from "react-toastify";
import { Loader, Placeholder } from 'rsuite';
import axios from "axios";
import moment from "moment";

interface Offer {
    "productOffer": boolean,
    "isActive": boolean,
    "_id": string,
    "name": string,
    "maxDiscount": number,
    "discount": number,
    "promocode": string,
    "useCount": number,
    "minOrderPrice": number,
    "startDate": string,
    "expireDate": string,
    "isPercent": boolean,
    "offerType": string,
    "offerUserType": string
    "type": string,
    "description": string,
    "cartDescription": string,
    "created": number,
    "updated": number,
    "date": string,
    "id": number,
    "__v": number,
    "product": []
}

const SinglePromocode = ({ promocodeId, setSelectedPromoCode }) => {
    const [promocode, setPromocode] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await getDataSec(`${baseURL}/offer/${promocodeId}`);
                if (result.success) {
                    let startDate = moment(result.data.startDate).format('YYYY-MM-DD');  // example initial date
                    let expireDate = moment(result.data.expireDate).format('YYYY-MM-DD');
                    setPromocode({ ...result.data, startDate, expireDate });
                }
                else {
                    toast.error(result.message);
                }
            }
            catch (error) {
                toast.error('Error fetching data:', error);
            }
            finally {
                setLoading(false)
            }
        }
        if (promocodeId !== "new") {
            getData();
        } else {
            setPromocode({
                name: "",
                discount: 0,
                promocode: '',
                description: '',
                cartDescription: '',
                minOrderPrice: 0,
                useCount: 0,
                maxDiscount: 0,
                isPercent: true,
                startDate: moment().format('YYYY-MM-DD'),
                expireDate: moment().format('YYYY-MM-DD'),
                offerUserType: 'newuser',
                offerType: 'offer',
                type: 'instant',
                isActive: false
            });
            setLoading(false);
        }
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'scroll'
        }
    }, [promocodeId]);

    const handleStartDateChange = (e) => {
        const date = moment(e.target.value).format('YYYY-MM-DD');
        setPromocode((prev) => ({
            ...prev,
            startDate: date,
        }));
    };

    const handleExpireDateChange = (e) => {
        const date = moment(e.target.value).format('YYYY-MM-DD');
        setPromocode((prev) => ({
            ...prev,
            expireDate: date,
        }));
    };

    const savePromoCode = async () => {
        try {
            let expireDate = moment(promocode.expireDate).toISOString(),
                startDate = moment(promocode.startDate).toISOString();
            let result;
            if (promocodeId === 'new') {
                const url = `${baseURL}/offer`;
                result = await postDataSec(url, { ...promocode, expireDate, startDate });
            } else {
                const url = `${baseURL}/offer/${promocodeId}`;
                result = await putDataSec(url, { ...promocode, expireDate, startDate });
            }
            console.log(result)
            if (result.success) {
                toast.success(result.message);
                setSelectedPromoCode('')
            }
            else {
                toast.error("Cannot save promocode : ", result.message)
            }
        } catch (err) {
            console.log(err);
            toast.error("error in saving promocode");
        }
    }

    return (
        <div className="editModalContainer">
            <div className="editModal">
                {
                    loading ?
                        <Loader inverse center content="loading..." />
                        : <>
                            <div className="editModalHeader">
                                <h2 className="editModalTitle">Edit Promocode</h2>
                                <button className="closeIcon" onClick={() => setSelectedPromoCode('')}>&times;</button>
                            </div>
                            <div className="editModalBody">
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Offer Name</label>
                                        <input required placeholder="Offers Name" value={promocode?.name} onChange={(e) => { setPromocode(prev => ({ ...prev, name: e.target.value })) }} />
                                    </div>
                                    <div className="form-group" >
                                        <label>Promocode</label>
                                        <input required placeholder="Promocode name(should be unique)" value={promocode?.promocode} onChange={(e) => { setPromocode(prev => ({ ...prev, promocode: e.target.value })) }} />
                                    </div>
                                </div>

                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label style={{ fontWeight: 600 }}>Discount Type</label>
                                        <select value={promocode?.isPercent?.toString()}
                                            onChange={(e) => {
                                                setPromocode(prev => ({
                                                    ...prev,
                                                    isPercent: e.target.value === "true"
                                                }));
                                            }}
                                            style={{ padding: '6px 8px', borderRadius: 8, border: '1px solid #ddd', marginRight: 20 }}>
                                            <option value={"false"}>Flat Discount</option>
                                            <option value={"true"}>Percentage</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label style={{ fontWeight: 600 }}>User Type</label>
                                        <select value={promocode?.offerUserType}
                                            onChange={(e) => {
                                                setPromocode(prev => ({
                                                    ...prev,
                                                    offerUserType: e.target.value
                                                }));
                                            }}
                                            style={{ padding: '6px 8px', borderRadius: 8, border: '1px solid #ddd', marginRight: 20, backgroundColor: 'white' }}>
                                            <option value={"existinguser"}>Returning User</option>
                                            <option value={"newuser"}>New User</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label style={{ fontWeight: 600 }}>Type</label>
                                        <select value={promocode?.type}
                                            onChange={(e) => {
                                                setPromocode(prev => ({
                                                    ...prev,
                                                    offerUserType: e.target.value
                                                }));
                                            }}
                                            style={{ padding: '6px 8px', borderRadius: 8, border: '1px solid #ddd' }}>
                                            <option value={"cashback"}>Cashback</option>
                                            <option value={"instant"}>Instant</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Discount {promocode?.isPercent ? '(in percent)' : '(in rupees)'}</label>
                                        <input required type="number" value={promocode?.discount} onChange={(e) => { setPromocode(prev => ({ ...prev, discount: e.target.valueAsNumber })) }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Maximum discount Value</label>
                                        <input required type="number" value={promocode?.maxDiscount} onChange={(e) => { setPromocode(prev => ({ ...prev, maxDiscount: e.target.valueAsNumber })) }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Minimum Order Price</label>
                                        <input required type="number" value={promocode?.minOrderPrice} onChange={(e) => { setPromocode(prev => ({ ...prev, minOrderPrice: e.target.valueAsNumber })) }} />
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input required type="date" value={promocode?.startDate} onChange={handleStartDateChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Expiry Date</label>
                                        <input required type="date" value={promocode?.expireDate} onChange={handleExpireDateChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Use Count</label>
                                        <input required type="number" value={promocode?.useCount} onChange={(e) => { setPromocode(prev => ({ ...prev, useCount: e.target.valueAsNumber })) }} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Offer Heading</label>
                                    <textarea required placeholder="Offer Description" value={promocode?.cartDescription} rows={5} onChange={(e) => { setPromocode(prev => ({ ...prev, cartDescription: e.target.value })) }} />
                                </div>
                                <div className="form-group">
                                    <label>Term & Conditions</label>
                                    <textarea required placeholder="Offer Term and Conditions" rows={5} value={promocode?.description} onChange={(e) => { setPromocode(prev => ({ ...prev, description: e.target.value })) }} />
                                </div>
                            </div>
                            <div className="editModalFooter">
                                <button className="btn btn-secondary" onClick={() => setSelectedPromoCode('')}>Cancel</button>
                                <button className="btn btn-primary" onClick={() => savePromoCode()}>Save</button>
                            </div>
                        </>

                }
            </div>
        </div>
    )
}

export default SinglePromocode;