import React, { useEffect, useState } from 'react';
import './ServingArea.css'
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';

const ServingArea = () => {
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [servingAreaList, setServingAreaList] = useState([]);

    const getCity = async () => {
        try {
            const result = await getDataSec(`${baseURL}/city`);
            if (result.success) {
                setCityList(result.cities)
            } else {
                console.log(result.message)
                toast.error("Unable to fetch cities");
            }
        } catch (err) {
            console.log(err);
            toast.error("Unable to fetch cities")
        }
    }

    useEffect(() => {
        getCity();
    }, [])

    const getServingArea = async (id) => {
        try {
            const result = await getDataSec(`${baseURL}/serveArea/${id}`);
            if (result.success) {
                setServingAreaList(result.data.servingAreas)
            } else {
                console.log(result.message)
                toast.error("Error in getting Serve Areas")
            }
        } catch (err) {
            toast.error("Error in getting Serve Areas")
        }
    }

    const handleCityShow = (id) => {
        setSelectedCity(id);
        getServingArea(id);
    }

    return (
        <div className='servingAreaRootContainer'>
            <div className="cityContainer">
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', maxWidth: 50, backgroundColor: 'red', justifyContent: 'flex-end' }}>
                    <button>Add City +</button>
                </div>
                <div style={{ display: 'flex', flex: 1, marginTop: 20, flexDirection: 'column' }}>
                    {cityList?.map((item, index) => {
                        return <div className='cityListItem' >
                            <p>{item.name}</p>
                            <button onClick={() => { handleCityShow(item._id) }}>Show</button>
                            <button>Delete</button>
                        </div>
                    })}
                </div>
            </div>

            <div className="servingAreaContainer">
                {servingAreaList.map((index, item) => {
                    return <div>
                        <p>{index.areaName}</p>
                    </div>
                })}
            </div>
        </div>
    );
}

export default ServingArea;
