import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { Loader } from 'rsuite';

const PromoCodeOrders = ({ promocode, setSelectedPromoCode }) => {
    const [loading, setLoading] = useState(true);
    const [orderList, setOrderList] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [chartData, setChartData] = useState(null);
    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0]; // First day of the current month
    });

    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        return now.toISOString().split('T')[0]; // Today's date
    });


    const getOrders = async () => {
        try {
            setLoading(true);
            const res = await getDataSec(`${baseURL}/offer/orders?offerId=${promocode}`);
            const orders = res.orders || [];
            setOrderList(orders);

            // Filter out cancelled orders
            const notCancelledOrders = orders.filter(order => order.status !== 'cancelled');
            const notCancelledOrdersCount = notCancelledOrders.length;
            setTotalOrders(notCancelledOrdersCount);
            const notCancelledOrderAmount = notCancelledOrders.reduce((acc, order) => acc + order.amount, 0);
            setTotalAmount(notCancelledOrderAmount);

            processChartData(notCancelledOrders);
        } catch (err) {
            console.log("Error in getting orders", err);
        } finally {
            setLoading(false);
        }
    };

    const processChartData = (orders) => {
        // Get all dates in the range
        const dateRange = generateDateRange(startDate, endDate);

        // Map orders to date range
        const dailyData = dateRange.reduce((acc, date) => {
            acc[date] = { orders: 0, amount: 0 }; // Initialize with 0
            return acc;
        }, {});

        orders.forEach(order => {
            const date = new Date(order.date).toLocaleDateString();
            if (dailyData[date]) {
                dailyData[date].orders += 1;
                dailyData[date].amount += order.amount;
            }
        });

        const labels = Object.keys(dailyData);
        const ordersData = labels.map(date => dailyData[date].orders);
        const amountsData = labels.map(date => dailyData[date].amount);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Number of Orders',
                    data: ordersData,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Total Amount (₹)',
                    data: amountsData,
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
            ],
        });
    };

    const generateDateRange = (start, end) => {
        const dates = [];
        let currentDate = new Date(start);
        const endDate = new Date(end);

        while (currentDate <= endDate) {
            dates.push(currentDate.toLocaleDateString());
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const handleFilter = () => {
        const filteredOrders = orderList.filter(order => {
            const orderDate = new Date(order.date).toISOString().split('T')[0];
            return orderDate >= startDate && orderDate <= endDate;
        });
        processChartData(filteredOrders);
    };

    useEffect(() => {
        getOrders();
    }, [promocode]);

    return (
        <div className="editModalContainer">
            <div className="editModal" style={{ overflowY: 'scroll', height: "80%" }}>
                <div className="editModalHeader">
                    <h2 className="editModalTitle">Orders</h2>
                    <p>Total Orders : {totalOrders}</p>
                    <p>Total Amount : {totalAmount}</p>
                    <button className="closeIcon" onClick={() => setSelectedPromoCode('')}>&times;</button>
                </div>
                <div className="editModalBody">
                    <div style={{ marginBottom: '20px' }}>
                        <label>
                            Start Date:
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </label>
                        <label style={{ marginLeft: '10px' }}>
                            End Date:
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </label>
                        <button style={{ marginLeft: '10px' }} onClick={handleFilter}>Apply Filter</button>
                    </div>
                    {loading ? (
                        <Loader inverse center content="loading..." />
                    ) : (
                        <div style={{}}>
                            {chartData && (
                                <div style={{ marginBottom: '20px', padding: 40, flex: 1 }}>
                                    <Line
                                        data={chartData}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Daily Orders and Amount',
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                        text: 'Date',
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Count/Amount',
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                            <div style={{ flex: 1 }}>
                                <table style={{ borderCollapse: 'separate', borderSpacing: 0, }}>
                                    <thead>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Promocode Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderList.map((order, index) => (
                                            <tr key={index} style={{ border: '1px solid #000' }}>
                                                <td>{order.id}</td>
                                                <td>{order.amount}</td>
                                                <td>{new Date(order.date).toLocaleString()}</td>
                                                <td>{order.couponDiscount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PromoCodeOrders;
