import React, { useEffect, useRef, useState } from "react";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import "./Chatbot.css";
import Switch from "@mui/material/Switch";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import { Send } from "@mui/icons-material";

const Chatbot = () => {
    const [ticketList, setTicketList] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalTickets, setTotalTickets] = useState(0);
    const [tempFileLinks, setTempFileLinks] = useState([]);
    const [messages, setMessages] = useState([]);
    const [ticketDetails, setTicketDetails] = useState();
    const inputBoxRef = useRef(null);
    const chatboxRef = useRef(null);
    const [ticketType, setTicketType] = useState("open");
    const [customerType, setCustomerType] = useState("");
    const TextingGif = "/assets/images/texting.gif";

    const handleSendMessage = (text) => {
        setMessages((prev) => [
            ...prev,
            {
                message: text,
                loading: true,
                source: "server",
                date: Date.now(),
            },
        ]);
        const tempTick = ticketDetails?._id;
        postDataSec(baseURL + "/chatbot/resumeTicket", {
            ticketId: tempTick,
            message: text,
        })
            .then((res) => {
                if (res.success) {
                    setMessages(res.ticket?.chats);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const refreshChatbox = async (thisTicket) => {
        setMessages([]);
        setTicketDetails(null);
        getDataSec(`${baseURL}/chatbot/viewTicket?ticketId=${thisTicket._id}`)
            .then((res) => {
                setTicketDetails({
                    id: res.tickets?.id,
                    status: res.tickets?.ticketStatus,
                    _id: res.tickets?._id,
                    resolution: res.tickets?.resolution,
                });
                setMessages(res.tickets?.chats);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (selectedTicket) {
            refreshChatbox(selectedTicket);
        }
    }, [selectedTicket]);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    const getBrands = async () => {
        try {
            setLoading(true);
            setTotalTickets(0);
            const result = await getDataSec(
                baseURL +
                    "/chatbot/listAll?limit=10&page=" +
                    page +
                    "&ticketType=" +
                    ticketType +
                    "&customerType=" +
                    customerType
            );
            if (result.success) {
                setTicketList(result.tickets);
                setTotalTickets(result.totalTickets);
                setTotalPages(result.totalPages);
            } else {
                toast.error("Unable to fetch brands");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        } finally {
            setLoading(false);
        }
    };

    const [renderView, setRenderView] = useState(false);

    useEffect(() => {
        getBrands();
        document.body.style.overflow = "hidden";
        const permissions = JSON.parse(localStorage.getItem("permissions")).map(
            (item) => item.name
        );
        if (permissions.includes("chatbot")) {
            setRenderView(true);
        }
    }, [page, ticketType, customerType]);

    return !renderView ? (
        <div></div>
    ) : (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                padding: 20,
                gap: 20,
            }}
        >
            <div style={{}} className="tagListContainer">
                <div
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        borderRadius: 12,
                    }}
                >
                    <Button
                        disabled={loading || page === 1}
                        onClick={() => {
                            if (page !== 1) setPage(page - 1);
                        }}
                        style={{ width: "80%", textAlign: "center" }}
                    >
                        Prev {`<<`}{" "}
                    </Button>
                    <div style={{ width: "80%", textAlign: "center" }}>
                        Page {page} / {totalPages}
                    </div>
                    <Button
                        disabled={loading || page === totalPages}
                        onClick={() => {
                            if (page < totalPages) setPage(page + 1);
                        }}
                        style={{ width: "80%", textAlign: "center" }}
                    >
                        Next {`>>`}{" "}
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        maxHeight: 60,
                        marginBottom: 20,
                        flex: 1,
                        gap: 20,
                        justifyContent: "space-between",
                    }}
                >
                    <h3>Chats List</h3>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        placeholder="Search Ticket ID"
                        style={{
                            padding: 10,
                            flex: 1,
                            borderRadius: 8,
                            border: "1px solid #aaa",
                        }}
                    />
                    <div
                        style={{
                            // border: "1px solid #aaa",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <select
                            value={ticketType}
                            onChange={(e) => setTicketType(e.target.value)}
                        >
                            <option value="all">All</option>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                            <option value="resolved">Resolved</option>
                        </select>
                        Tickets
                    </div>
                    <div
                        style={{
                            // border: "1px solid #aaa",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <select
                            value={customerType}
                            onChange={(e) => setCustomerType(e.target.value)}
                        >
                            <option value="all">All</option>
                            <option value="online">Online </option>
                            <option value="offline">Offline</option>
                        </select>
                        Customers
                    </div>
                    <button
                        onClick={() => {
                            const phone = prompt("Enter phone number of user");
                            if (phone && phone.length === 10) {
                                postDataSec(baseURL + "/chatbot/openNew", {
                                    phoneNo: phone,
                                })
                                    .then((res) => {
                                        if (res.success) {
                                            getBrands().then(() => {
                                                setSelectedTicket(res.ticket);
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }
                        }}
                        style={{
                            border: "1px solid #eee",
                            padding: "10px 20px",
                            width: 120,
                            marginBottom: 10,
                            backgroundColor: "#ccc",
                            borderRadius: 8,
                            alignSelf: "flex-end",
                        }}
                    >
                        Open New +
                    </button>

                    <h3>Total: ( {totalTickets} )</h3>
                </div>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress size={48} color="info" />{" "}
                    </div>
                ) : (
                    <table
                        cellPadding={10}
                        style={{ borderColor: "#fff", overflow: "scroll" }}
                        border={1}
                    >
                        <thead>
                            <tr>
                                <th style={{ textAlign: "left" }}>Id #</th>
                                <th style={{ textAlign: "left" }}>Name</th>
                                <th style={{ textAlign: "left" }}>Status</th>
                                <th style={{ textAlign: "left" }}>Type</th>
                                <th style={{ textAlign: "left" }}>
                                    Created on
                                </th>
                                <th style={{ textAlign: "left" }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ticketList.map((item, index) => {
                                return (
                                    <tr className="" key={index}>
                                        <td>
                                            <p
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {item.id}
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {item.user?.phoneNo}
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                style={{
                                                    textTransform: "capitalize",
                                                    color:
                                                        item.ticketStatus ===
                                                        "resolved"
                                                            ? "green"
                                                            : "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {item.ticketStatus}
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {item.isOfflineCustomer
                                                    ? "Offline"
                                                    : "Online"}
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {moment(item.date).format(
                                                    "DD-MMM YYYY  hh:mm A"
                                                )}
                                            </p>
                                        </td>
                                        <td>
                                            <div style={{}}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            setSelectedTicket(
                                                                null
                                                            );
                                                            setSelectedTicket(
                                                                item
                                                            );
                                                        }}
                                                        style={{
                                                            border: "1px solid #eee",
                                                            padding:
                                                                "10px 20px",
                                                            width: 120,
                                                            marginBottom: 10,
                                                            backgroundColor:
                                                                "#0A3B65",
                                                            borderRadius: 8,
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#fff",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Chat
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="tagEditorContainer">
                {selectedTicket ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ margin: 4, padding: 4 }}>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    <tr
                                        style={{
                                            borderBottom: "1px solid black",
                                        }}
                                    >
                                        <td style={{ padding: 4 }}>
                                            #{ticketDetails?.id}
                                        </td>
                                        <td style={{ padding: 4 }}>
                                            {ticketDetails?.status}
                                        </td>
                                        <td style={{ padding: 4 }}>
                                            {ticketDetails?.status !==
                                            "resolved" ? (
                                                <Button
                                                    onClick={() => {
                                                        let message = prompt(
                                                            "Enter message to user"
                                                        );
                                                        if (!message)
                                                            toast.error(
                                                                "Please enter a valid message"
                                                            );
                                                        else
                                                            postDataSec(
                                                                baseURL +
                                                                    "/chatbot/resolveTicket",
                                                                {
                                                                    ticketId:
                                                                        ticketDetails?._id,
                                                                    message,
                                                                }
                                                            )
                                                                .then((res) => {
                                                                    if (
                                                                        res.success
                                                                    ) {
                                                                        toast.success(
                                                                            "Ticket resolved."
                                                                        );
                                                                        setSelectedTicket(
                                                                            null
                                                                        );
                                                                        getBrands();
                                                                    }
                                                                })
                                                                .catch(
                                                                    (err) => {
                                                                        console.log(
                                                                            err
                                                                        );
                                                                    }
                                                                );
                                                    }}
                                                    variant="contained"
                                                    color="inherit"
                                                    style={{
                                                        color: "white",
                                                        fontSize: 12,
                                                        backgroundColor:
                                                            "#0a3b64",
                                                    }}
                                                >
                                                    Mark as Solved
                                                </Button>
                                            ) : (
                                                ticketDetails?.resolution
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            ref={chatboxRef}
                            style={{
                                overflowY: "scroll",
                                maxHeight: "50vh",
                                padding: "8px",
                                backgroundColor: "white",
                            }}
                        >
                            {messages?.map((message, index) => (
                                <div
                                    key={message._id}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems:
                                            message.source === "customer"
                                                ? "flex-start"
                                                : "flex-end",
                                        margin: "8px 0",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "12px",
                                            maxWidth: "256px",
                                            borderRadius: "12px",
                                            backgroundColor:
                                                message?.source === "server"
                                                    ? "#E5E7EB"
                                                    : "#3B82F6",
                                            color:
                                                message?.source === "server"
                                                    ? message?.loading
                                                        ? "#aaa"
                                                        : "#1F2937"
                                                    : "white",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 4,
                                        }}
                                    >
                                        {message?.message}
                                        {message?.loading && (
                                            <CircularProgress
                                                size={16}
                                                color="inherit"
                                                style={{
                                                    color: "#aaa",
                                                }}
                                            />
                                        )}
                                    </div>
                                    {!message?.loading && (
                                        <p className="dateFormat">
                                            {moment(message.date).format(
                                                "ddd DD MMM hh:mm A"
                                            )}
                                        </p>
                                    )}
                                </div>
                            ))}
                            {loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        margin: "8px 0",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "12px",
                                            maxWidth: "256px",
                                            color: "white",
                                        }}
                                    >
                                        <img
                                            height={50}
                                            width={50}
                                            src={TextingGif}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {messages &&
                            messages?.length >= 1 &&
                            ticketDetails?.status !== "resolved" &&
                            !loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        borderTop: "1px solid black",
                                        paddingTop: 4,
                                        margin: 4,
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            border: "2px solid #D1D5DB",
                                            display: "flex",
                                            padding: "8px",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <input
                                            ref={inputBoxRef}
                                            type="text"
                                            placeholder="Type a message"
                                            style={{
                                                flex: 1,
                                                outline: "none",
                                                border: "none",
                                            }}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "Enter" &&
                                                    e.target.value.trim()
                                                ) {
                                                    handleSendMessage(
                                                        e.target.value.trim()
                                                    );
                                                    e.target.value = "";
                                                }
                                            }}
                                        />
                                        <div
                                            onClick={() => {
                                                if (
                                                    inputBoxRef.current.value.trim() &&
                                                    !loading
                                                ) {
                                                    handleSendMessage(
                                                        inputBoxRef.current.value.trim()
                                                    );
                                                    inputBoxRef.current.value =
                                                        "";
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Send color="primary" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        {tempFileLinks.map((imageName, index) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        padding: "8px",
                                    }}
                                >
                                    <div style={{ flex: 1 }}>{index + 1})</div>
                                    <div style={{ flex: 1 }}>{imageName}</div>
                                    <div style={{ flex: 1 }}>
                                        <button
                                            style={{
                                                textDecoration: "underline",
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setTempFileLinks((prev) =>
                                                    prev.filter(
                                                        (item) =>
                                                            item !== imageName
                                                    )
                                                );
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>
                        Click on <strong>Chat button</strong> to open a specific
                        chat
                    </p>
                )}
            </div>
        </div>
    );
};

export default Chatbot;
