import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../assets/logo.jpg";
import UserSvg from "../../assets/avatar.svg";
import dashboardSvg from "../../assets/dashboard.svg";
import orderSvg from "../../assets/order.svg";
import categorySvg from "../../assets/category.svg";
import OfferIcon from "../../assets/OfferIcon.svg";
import SellerIcon from "../../assets/SellerIcon.svg";
import TagIcon from "../../assets/TagIcon.svg";
import NewDescription from "../../assets/NewDescription.svg";
import BrandsIcon from "../../assets/BrandsIcon.svg";
import ChatsIcon from "../../assets/ChatsIcon.svg";
import DesignIcon from "../../assets/DesignIcon.svg";
import productSvg from "../../assets/product.svg";
import bulkOrderSvg from "../../assets/bulkOrder.svg";
import customerSvg from "../../assets/customer.svg";
import deliveryBoySvg from "../../assets/deliveryBoy.svg";
import deliverySlotSvg from "../../assets/deliverySlot.svg";
import deliveryCharge from "../../assets/deliveryCharge.svg";
import addNewProduct from "../../assets/addNewProduct.svg";
import disableSlot from "../../assets/disableSlot.svg";
import admin from "../../assets/admin.svg";
import config from "../../assets/configSvg.svg";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../screens/Dashboard/Dashboard";
import IAM from "../../screens/IAM/IAM";
import Page404 from "../../screens/404/404";
import AppHomeScreen from "../../screens/AppHomeScreen/AppHomeScreen.tsx";
import Category from "../../screens/Category/Category.tsx";
import PromoCode from "../../screens/Promocode/PromoCode.tsx";
import Products from "../../screens/Products/Products.jsx";
import { SingleProductPage } from "../../screens/Products/SingleProductPage.tsx";
import Seller from "../../screens/Sellers/Seller.tsx";
import SinglePromocode from "../../screens/Promocode/SinglePromocode.tsx";
import Tags from "../../screens/Tags/Tags.tsx";
import Orders from "../../screens/Orders/Orders.js";
import Brands from "../../screens/Brands/Brands.js";
import Complaints from "../../screens/Complaints/Complaints.js";
import ComplaintDetail from "../../screens/Complaints/ComplaintDetail.js";
import ServingArea from "../../screens/ServingArea/ServingArea.tsx";
import { toast } from "react-toastify";
import { SingleProductNewPage } from "../../screens/Products/SingleProductNewPage.tsx";
import { SingleProductNewDescription } from "../../screens/Products/SingleProductNewDescription.tsx";
import { SingleProductNewPageTwo } from "../../screens/Products/SingleProductNewPageTwo.tsx";
import WithdrawalRequest from "../../screens/WithdrawalRequest/WithdrawalRequest.js";
import Chatbot from "../../screens/Chatbot/Chatbot.js";
import ConfigPage from "../../screens/config/config.js";
import Campaign from "../../screens/Campaigns/Campaign.jsx";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const userPermissions = JSON.parse(localStorage.getItem("permissions"));

// Get today's date
const today = new Date();
const todayDay = today.getDate().toString().padStart(2, "0");
const todayMonth = (today.getMonth() + 1).toString().padStart(2, "0");
const todayYear = today.getFullYear().toString();
const formattedToday = `${todayYear}-${todayMonth}-${todayDay}`;

// Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const yesterdayDay = yesterday.getDate().toString().padStart(2, "0");
const yesterdayMonth = (yesterday.getMonth() + 1).toString().padStart(2, "0");
const yesterdayYear = yesterday.getFullYear().toString();
const formattedYesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    const location = useLocation();
    const [selectedContent, setSelectedContent] = useState(location.pathname);
    console.log(location.pathname);
    const [menu, setMenu] = useState([]);

    const setMenuItem = () => {
        let menuItems = [];
        let permissions = userPermissions;

        if (permissions) {
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "dashboard" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Dashboard",
                    icon: dashboardSvg,
                    route: "/",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "complaints" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Complaints",
                    icon: OfferIcon,
                    route: "/complaints",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "products" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Products",
                    icon: productSvg,
                    route: "/products",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "homescreen" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "HomeScreen",
                    icon: DesignIcon,
                    route: "/apphomescreen",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "tags" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "tags",
                    icon: TagIcon,
                    route: "/tags",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "brands" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Brands",
                    icon: BrandsIcon,
                    route: "/brands",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "chatbot" &&
                        permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Chats",
                    icon: ChatsIcon,
                    route: "/chatbot",
                });
            }
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "config" && permission.read === true
                )
            ) {
                menuItems.push({
                    title: "Config",
                    icon: config,
                    route: "/config",
                });
            }
        } else {
            toast.error("No Permissions found.");
        }
        setMenu(menuItems);
    };

    useEffect(() => {
        setMenuItem();
    }, []);
    const navigate = useNavigate();
    const renderContent = () => {
        return (
            <Routes>
                <Route path="/" element={<Dashboard />} />
                {/* <Route path="/iam" element={<IAM />} /> */}
                <Route path="/404" element={<Page404 />} />
                <Route path="/apphomescreen" element={<AppHomeScreen />} />
                <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/products" element={<Products />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/product" element={<SingleProductPage />} />
                <Route
                    path="/editProduct/:id/:catId"
                    element={<SingleProductNewPage />}
                />
                <Route
                    path="/newDescription/:id/:catId"
                    element={<SingleProductNewDescription />}
                />
                <Route
                    path="/addProduct"
                    element={<SingleProductNewPageTwo />}
                />
                <Route path="/category" element={<Category />} />
                <Route path="/servingarea" element={<ServingArea />} />
                <Route path="/complaint/:id" element={<ComplaintDetail />} />
                <Route path="/complaints" element={<Complaints />} />
                <Route path="/seller" element={<Seller />} />
                <Route path="/config" element={<ConfigPage />} />
                <Route path="/promocode" element={<PromoCode />} />
                <Route
                    path="/withdrawalrequest"
                    element={<WithdrawalRequest />}
                />
                <Route path="/promocode/:id" element={<SinglePromocode />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/config" element={<ConfigPage />} />
                <Route path="/campaign" element={<Campaign />} />
            </Routes>
        );
    };
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            {/* <CssBaseline /> */}
            <AppBar elevation={0} position="fixed" open={open}>
                <Toolbar
                    style={{
                        backgroundColor: "white",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                color: "#696969",
                                boxShadow: "none",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                            }}
                            src={logo}
                            id="logo"
                            alt="AkbLogo"
                        />
                        <div style={{ width: 20 }} />
                        <Typography
                            color={"black"}
                            variant="h6"
                            noWrap
                            component="div"
                        >
                            Admin Panel <strong>AKB</strong>
                        </Typography>
                    </div>
                    <button
                        style={{
                            cursor: "pointer",
                            backgroundColor: "#00000005",
                            border: "1px solid #0000001A",
                            padding: "12px 25px",
                            borderRadius: "15px",
                        }}
                        onClick={() => {
                            localStorage.removeItem("token2");
                            localStorage.removeItem("permissions");
                            window.location.reload();
                        }}
                    >
                        Logout
                    </button>
                </Toolbar>
            </AppBar>
            <Drawer
                style={{ borderRight: "none" }}
                variant="permanent"
                open={open}
            >
                <DrawerHeader
                    sx={{
                        backgroundColor: "white",
                        display: "flex",
                        borderRight: "none",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <div
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                            }}
                            src={UserSvg}
                            alt="user"
                            id="profileImage"
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: 14 }}>Subodh Avasthi</div>
                        <div style={{ fontSize: 12 }}>Store Manager</div>
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <List style={{ borderRight: "none" }}>
                    {menu?.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                                paddingBottom: 0.5,
                            }}
                        >
                            <ListItemButton
                                onClick={() => {
                                    setSelectedContent(item.route);
                                    navigate(item.route, {
                                        state: {
                                            page: 1,
                                            snf: {
                                                sortField: "totalOrders",
                                                sortOrder: -1,
                                            },
                                        },
                                    });
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,

                                    backgroundColor:
                                        item.route === selectedContent
                                            ? "#ccc"
                                            : "transparent",
                                    "&:hover": {
                                        backgroundColor:
                                            item.route !== selectedContent
                                                ? "rgba(0,0,0,0.2)    "
                                                : "#ccc",
                                    },
                                }}
                            >
                                <img
                                    alt="hello"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                    src={item.icon}
                                />
                                <ListItemText
                                    primary={item.title}
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        paddingLeft: 1,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flex: 1,
                    minWidth: 1000,
                }}
            >
                <DrawerHeader />
                {renderContent()}
            </Box>
        </Box>
    );
}
