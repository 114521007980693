import React, { useEffect, useMemo, useState } from "react";
import "./Products.css";
import AllProductList from "./AllProductList.tsx";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getDataSec, postDataSec } from "../../Apis/Api.js";
import { baseURL, pageLimit } from "../../config/config.js";
import { SingleProductPage } from "./SingleProductPage.tsx";
import Papa from "papaparse";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { SingleProductNewPage } from "./SingleProductNewPage.tsx";
import axios from "axios";
const Products = () => {
    const [tree, setTree] = useState([]);

    const convertArrayToCSV = (array) => {
        const headers = Object.keys(array[0]);
        const csvRows = [];
        csvRows.push(headers.join(","));
        array.forEach((row) => {
            const values = headers.map((header) => row[header]);
            csvRows.push(values.join(","));
        });

        return csvRows.join("\n");
    };

    const exportData = async () => {
        try {
            setExporting(true);
            const response = await fetch(`${baseURL}/product/exportCsv`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token2"),
                },
            });
            if (!response.ok) {
                return toast.error("Error combining data");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `Seller Products ${moment(new Date()).format("DD MMM")}.csv`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            toast.error("Error exporting data");
            console.log("Error exporting data", error);
        } finally {
            setExporting(false);
        }
    };

    const [bulkUpload, setBulkUpload] = useState(null);
    const bulkUploadRef = React.useRef(null);
    const [bulkImportLoading, setBulkImportLoading] = useState();
    const [brandName, setBrandName] = useState();
    const [isSearching, setIsSearching] = useState(false);
    const [products, setProducts] = useState([]);
    const [noMoreProducts, setNoMoreProducts] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [showModal, setShowModal] = useState();
    const [page, setPage] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [disableButtons, setDisableButtons] = useState(false);
    const navigate = useNavigate();
    const [exporting, setExporting] = useState(false);
    const handleFileUpload = (file) => {
        setBulkImportLoading(true);
        Papa.parse(file, {
            header: true,
            complete: function (results) {
                const filteredData = results.data
                    .map((row) => {
                        Object.keys(row).forEach((key) => {
                            if (row[key] === "FALSE") {
                                row[key] = false;
                            }
                            if (row[key] === "TRUE") {
                                row[key] = true;
                            }
                        });
                        return row;
                    })
                    .filter((row) =>
                        Object.values(row).every(
                            (value) => value !== null && value !== ""
                        )
                    );
                postDataSec(baseURL + "/product/add/bulk", {
                    products: filteredData,
                })
                    .then((res) => {
                        if (res && res.success) {
                            setBulkImportLoading(false);
                            getData().then();
                            toast.success(res?.message || "Import Successfull");
                        } else {
                            toast.info(res?.message || "Something went wrong");
                        }
                        setBulkImportLoading(false);
                    })
                    .catch((err) => {
                        toast.error(err?.message || "Error in bulk upload");
                        console.log("Error in bulk upload", err);
                        setBulkImportLoading(false);
                    });
                setBulkUpload(null);
            },
            error: function (error) {
                console.error("Error parsing CSV file: ", error);
                setBulkUpload(null);
            },
        });
    };

    useEffect(() => {
        if (bulkUpload) {
            handleFileUpload(bulkUpload);
        }
    }, [bulkUpload]);

    const getData = async () => {
        setProducts([]);
        setIsSearching(true);
        if (!isSearching && !noMoreProducts) {
            setLoadingProducts(true);
            try {
                let apiUrl = "";
                if (searchKeyword.length > 0)
                    apiUrl = `${baseURL}/product/list?page=${page}&limit=100&keyword=${encodeURIComponent(
                        searchKeyword.toLowerCase().trim()
                    )}`;
                else apiUrl = `${baseURL}/product/list?page=${page}&limit=100`;
                const result = await getDataSec(apiUrl);
                if (result.success) {
                    if (page === 0) {
                        setProducts(result.products);
                    } else {
                        setProducts((prev) => [...prev, ...result.products]);
                    }
                } else {
                    toast.info(result.message);
                    setIsSearching(false);
                }
            } catch (error) {
                console.error("Error fetching data");
            } finally {
                setLoadingProducts(false);
                setIsSearching(false);
            }
        }
    };

    const handleSearch = () => {
        if (page === 0) {
            getData().then();
        } else {
            setPage(0);
        }
    };
    useEffect(() => {
        const getCategories = async () => {
            const result = await getDataSec(baseURL + "/brands");
            if (result.success) {
                setBrandName(result.brand);
            } else {
                console.log("Brand Names cannot be fetched");
            }
            const result2 = await getDataSec(baseURL + "/category/tree/v2");
            if (result2.success === true) {
                setTree(result2.category);
            } else {
                console.log("error in fetching categories", result.message);
            }
        };
        getCategories();
        getData().then();
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "products" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "products" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
    }, []);

    return (
        <div>
            {/* {selectedProductId !== null && (
        <SingleProductPage
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          tree={tree}
          brands={brandName}
        />
      )} */}
            {showModal && (
                <SingleProductNewPage
                    productDetail={
                        typeof showModal === "string" ? showModal : defaultProd
                    }
                    closePopup={() => setShowModal(false)}
                />
            )}
            <div
                style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "15px",
                    gap: 10,
                    margin: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px #0000001A",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        flex: 1,
                        gap: 20,
                        border: "0px solid red",
                    }}
                >
                    {/* <div
            style={{
              flexDirection: "row",
              flex: 0.6,
              display: "flex",
              gap: 20,
            }}
          >
            <div style={{ flex: 1 }}>
              <span style={{ fontSize: 24, fontWeight: "bolder" }}>Filter</span>
            </div>
            <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
              onChange={(event) => {
                handleSetCategory(event.target.value);
              }}
            >
              <option value="">Category</option>
              {mapCategories()}
            </select>
            <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
              onChange={(event) => handleSetSubCategory(event.target.value)}
            >
              <option value="">Sub Category</option>
              {selectedCategoryId !== null ? mapSubCategories() : <></>}
            </select>
            <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
              onChange={(event) =>
                setSelectedLeafCategoryId(event.target.value)
              }
            >
              <option value="">Leaf Category</option>
              {selectedSubCategoryID !== null ? mapLeafCategories() : <></>}
            </select>
            <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
            >
              <option value="today">Brands</option>
              {brandName !== null ? mapBrandNames() : <></>}
            </select>
          </div> */}
                    {/* <div
            style={{
              flexDirection: "row",
              flex: 0.4,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                flex: 0.5,
                gap: 20,
                justifyContent: "flex-end",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <button
                onClick={handleSearch}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffef03",
                  color: "#000",
                  borderWidth: 1,
                  padding: 10,
                  fontSize: 14,
                  width: "50%",
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderColor: "#dacc00",
                }}
              >
                Search
              </button>
              <button
                onClick={() => {
                  setSelectedBrandId(null);
                  setSelectedCategoryId(null);
                  setSelectedSubCategoryId(null);
                  setSelectedLeafCategoryId(null);
                  setSearchKeyword("");
                  setCategoryItem(null);
                  setProducts([]);
                  getData().then();
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fce8e8",
                  color: "#000",
                  borderWidth: 1,
                  padding: 10,
                  fontSize: 14,
                  width: "50%",
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderColor: "#e21b1b",
                }}
              >
                Clear
              </button>
            </div>
          </div> */}
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                    }}
                >
                    <div style={{ flex: 0.6, display: "flex", gap: 20 }}>
                        <input
                            style={{
                                flex: 5,
                                paddingLeft: 10,
                                border: "1px solid #eee",
                            }}
                            placeholder="Search by Product Name , SKU , Barcode"
                            value={searchKeyword}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                        />
                        <div style={{ width: 20 }}>
                            {isSearching && (
                                <CircularProgress
                                    size={18}
                                    sx={{ color: "#aaa" }}
                                />
                            )}
                        </div>
                        {/* <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="custom">Custom</option>
            </select>
            <select
              style={{
                flex: 1,
                borderRadius: 10,
                textAlign: "center",
                border: "1px solid #eee",
              }}
            >
              <option value="today">Sort By A-Z</option>
              <option value="yesterday">Sort by Z-A</option>
            </select> */}
                    </div>
                    <div
                        style={{
                            flexDirection: "row",
                            gap: 20,
                            flex: 0.4,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 0.5,
                                gap: 20,
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                disabled={bulkImportLoading}
                                onClick={() => {
                                    handleSearch();
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#ffef03",
                                    color: "#000",
                                    borderWidth: 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#dacc00",
                                }}
                            >
                                Search
                            </button>
                            <input
                                ref={bulkUploadRef}
                                onChange={(e) => {
                                    setBulkUpload(e.target.files[0]);
                                }}
                                type="file"
                                style={{ display: "none" }}
                            />
                            <button
                                disabled={bulkImportLoading}
                                onClick={() => {
                                    if (bulkUpload) {
                                        bulkUploadRef.current.value = "";
                                    } else {
                                        bulkUploadRef?.current?.click();
                                    }
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: bulkImportLoading
                                        ? "#eee"
                                        : "#e7f5f0",
                                    color: "#000",
                                    borderWidth: bulkImportLoading ? 0 : 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#0d9e67",
                                }}
                            >
                                {bulkImportLoading ? (
                                    <CircularProgress
                                        size={18}
                                        sx={{ color: "#aaa" }}
                                    />
                                ) : (
                                    "Import"
                                )}
                            </button>
                            <button
                                disabled={exporting}
                                onClick={exportData}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: exporting
                                        ? "#eee"
                                        : "#f2f2f2",
                                    color: "#000",
                                    borderWidth: exporting ? 0 : 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#cecece",
                                }}
                            >
                                {exporting ? (
                                    <CircularProgress
                                        size={18}
                                        sx={{ color: "#aaa" }}
                                    />
                                ) : (
                                    "Export"
                                )}
                            </button>
                            <button
                                onClick={() => {
                                    navigate("/addProduct");
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#0D9E67",
                                    color: "#fff",
                                    borderWidth: 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#0D9E67",
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <AllProductList
                page={page}
                setPage={setPage}
                loadingProducts={loadingProducts}
                products={products}
                noMoreProducts={noMoreProducts}
                disablebuttons={disableButtons}
                setSelectedProductId={setShowModal}
            />
        </div>
    );
};

export default Products;

const defaultProd = {
    barCode: "",
    name: "",
    description: "",
    recommendedAttribute: "",
    mrp: null,
    purchasePrice: null,
    minSellPrice: null,
    price: null,
    membershipPrice: null,
    hsnCode: "",
    gst: null,
    urlKey: "",
    isOrder: false,
    isLastBuying: false,
    isSubscription: false,
    isMorningBuy: false,
    brand: {
        _id: "",
        image: [],
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
        total: null,
    },
    subBrand: {
        _id: "",
        image: null,
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        parentId: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
    },
    rootCatId: "",
    subCatId: "",
    leafCatId: "",
    images: [],
    tags: [],
    seo: {
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
    },
    shipping: {
        dimensions: {
            height: null,
            length: null,
            width: null,
        },
        weight: null,
    },
    competitor: {
        grofers: {
            productId: null,
            lastPrice: null,
            merchantId: null,
        },
        milkbasket: {
            productId: null,
            lastPrice: null,
        },
        bigbasket: {
            productId: null,
            lastPrice: null,
        },
        grocio: {
            productId: null,
            lastPrice: null,
        },
    },
    parentId: "",
    isParent: false,
};
