import React, { useEffect, useState } from "react";
import { getDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Complaints = () => {
    const [issues, setIssues] = useState([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const getData = async () => {
        try {
            setIssues([]);
            const result = await getDataSec(
                `${baseURL}/issues?start=${page - 1}&limit=10`
            );
            if (result.success) {
                setIssues(result.issueList);
            } else {
                toast.error("Cannot fetch complaints");
            }
        } catch (err) {
            console.log("error in fetching complaints :", err);
        }
    };
    useEffect(() => {
        getData();
    }, [page]);

    const renderComplaints = () => {
        return issues.map((item, index) => {
            return (
                <tr
                    style={{ textAlign: "left", cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                        navigate("/complaint/" + item._id);
                    }}
                >
                    <td style={{ textAlign: "left" }}>{item?.id}</td>
                    <td style={{ textAlign: "left" }}>
                        {moment(item?.date).format("DD MMM yyyy")}
                    </td>
                    <td style={{ textAlign: "left" }}>{item?.phoneNo}</td>
                    <td style={{ textAlign: "left" }}>
                        {item?.category || "Order Related"}
                    </td>
                    <td
                        style={{
                            textAlign: "left",
                            textTransform: "capitalize",
                        }}
                    >
                        {item.type}
                    </td>
                    <td
                        style={{
                            textAlign: "left",
                            textTransform: "capitalize",
                        }}
                    >
                        {item.status}
                    </td>
                </tr>
            );
        });
    };

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                padding: 20,
                overflow: "scroll",
            }}
        >
            {issues.length > 0 ? (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        padding: 20,
                        backgroundColor: "white",
                        borderRadius: 12,
                    }}
                >
                    <table className="promocodeContainer">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "left" }}>Sr No</th>
                                <th style={{ textAlign: "left" }}>Date</th>
                                <th style={{ textAlign: "left" }}>Phone No</th>
                                <th style={{ textAlign: "left" }}>Type</th>
                                <th style={{ textAlign: "left" }}>Title</th>
                                <th style={{ textAlign: "left" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>{renderComplaints()}</tbody>
                        <tfoot>
                            <tr
                                style={{ textAlign: "left", cursor: "pointer" }}
                            >
                                <td
                                    style={{
                                        textAlign: "left",
                                        borderRadius: 100,
                                    }}
                                >
                                    {page !== 0 && (
                                        <button
                                            onClick={() => {
                                                setPage((prev) => prev - 1);
                                            }}
                                            style={{
                                                boxShadow:
                                                    "2px 2px 2px 10px #eee",
                                                background: "#eee",
                                            }}
                                        >
                                            Previous Page
                                        </button>
                                    )}
                                </td>
                                <td style={{ textAlign: "left" }}></td>
                                <td style={{ textAlign: "left" }}></td>
                                <td
                                    style={{
                                        textAlign: "center",
                                        backgroundColor: "#eee",
                                        borderRadius: 100,
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            const requestedPage = parseInt(
                                                prompt("Enter Page Number")
                                            );
                                            if (requestedPage > 0) {
                                                setPage(requestedPage);
                                            }
                                        }}
                                        style={{}}
                                    >
                                        {page}
                                    </button>
                                </td>
                                <td style={{ textAlign: "left" }}></td>
                                <td
                                    style={{
                                        textAlign: "left",
                                        borderRadius: 100,
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            setPage((prev) => prev + 1);
                                        }}
                                        style={{
                                            boxShadow: "2px 2px 2px 10px #eee",
                                            background: "#eee",
                                        }}
                                    >
                                        Next Page
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Complaints;
