import axios from "axios";

export const getDataSec = async (url) => {
    try {
        const response = await axios.get(url, {
            headers: {
                "x-access-token": localStorage.getItem("token2"),
            },
        });
        return response.data;
    } catch (error) {
        console.log("Error: ", error);
    }
};
export const postDataSec = async (url, data) => {
    try {
        const response = await axios.post(url, data, {
            headers: {
                "x-access-token": localStorage.getItem("token2"),
            },
        });
        return response.data;
    } catch (error) {
        console.log("Error: ", error);
    }
};
export const putDataSec = async (url, data) => {
    try {
        const response = await axios.put(url, data, {
            headers: {
                "x-access-token": localStorage.getItem("token2"),
            },
        });
        return response.data;
    } catch (error) {
        console.log("Error: ", error);
    }
};
export const putDataSec2 = async (url, data, token) => {
    try {
        const response = await axios.put(url, data, {
            headers: {
                "x-access-token": token,
            },
        });
        return response.data;
    } catch (error) {
        console.log("Error: ", error);
    }
};
