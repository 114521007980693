import { toast } from "react-toastify";
import { baseURL, liveURL } from "../../config/config";
import React, { useState, useEffect } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import AutoFixHighOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
export const ProductCard = ({ item, index, disablebuttons, setSelectedProductId }) => {

    const navigate = useNavigate();
    const [allowDescription, setAllowDescription] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [blacklistedPinCodes, setBlacklistedPinCodes] = useState("");
    const [statevar, setStateVar] = useState(false);
    const [infoVar, setInfoVar] = useState({
        sellerProductId: "",
        quantity: 0,
        perUserOrderQuantity: 0,
        price: 0,
        sellPrice: 0,
        minSellPrice: 0,
        storeMinQuantity: 0,
        purchasePrice: 0,
        dotd_from_time: 0,
        dotd_to_time: 0,
    });
    const openModal = (data) => {
        console.log(data);
        if (!statevar) {
            if (data === undefined) {
                setBlacklistedPinCodes("");
            } else {
                setBlacklistedPinCodes(data);
            }
        }
        setIsOpen(true);
    };

    const [checker, setChecker] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [isOpen]);

    useEffect(() => {
        setInfoVar({
            sellerProductId: item.sellerProductId,
            quantity: item.quantity,
            perUserOrderQuantity: item.perUserOrderQuantity,
            price: item.price,
            sellPrice: item.sellPrice,
            minSellPrice: item.minSellPrice,
            storeMinQuantity: item.storeMinQuantity,
            purchasePrice: item.purchasePrice,
            dotd_from_time: item.dotd_from_time,
            dotd_to_time: item.dotd_to_time,
        });
        if (localStorage.permissions) {
            const permissions = JSON.parse(localStorage.permissions);
            console.log("Permissions are", permissions);
            if (
                permissions.some(
                    (permission) => permission.name === "newDescription" && permission.read === true
                )
            ) {
                setAllowDescription(true);
            }
        }
        setChecker(true);
    }, []);

    useEffect(() => {
        if (checker) setDisableSave(false);
    }, [checker, infoVar]);

    const closeModal = () => {
        setStateVar(true);
        setIsOpen(false);
    };
    return (
        <>
            <tr key={index}>
                <td>
                    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", gap: 5 }}>
                        <p className="blacktext">SKU</p>
                        <p className="greytext">{item.sku}</p>
                        <p className="blacktext">GSTIN</p>
                        <p className="greytext">{item.hsnCode}</p>
                    </div>
                </td>
                <td>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div>
                            <img alt="ProductImage" src={`${liveURL}/public/product/${item.id}/${item.images[0]}`} className="productImage" />
                        </div>
                        <div>
                            <p className="blacktext">{item.name}</p>
                            <p className="greytext">{item.recommendedAttribute}</p>
                        </div>
                    </div>
                </td>
                <td >
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} >
                        <button
                            disabled={disablebuttons}
                            style={{ backgroundColor: disablebuttons ? "#eee" : "#0d9e67", border: disablebuttons ? "1px solid #aaa" : "none", color: disablebuttons ? "#aaa" : "white" }}
                            onClick={() => {
                                navigate(`/editProduct/${item._id}/${item.categoryId}`);
                            }}
                            className="SaveBtn">
                            <EditOutlinedIcon style={{ height: 15, width: 15 }} />
                            Edit
                        </button>
                        {allowDescription && <button
                            style={{ backgroundColor: '#fff', border: "1px solid #0d9e67", color: "#0d9e67" }}
                            onClick={() => {
                                window.open(`/newDescription/${item._id}/${item.categoryId}`);
                            }}
                            className="SaveBtn">
                            <AutoFixHighOutlinedIcon style={{ height: 15, width: 15 }} />
                            Enhance
                        </button>}
                    </div>
                </td>
            </tr >
        </>
    );
};