import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const ConfigPage = () => {
    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch config from API
        const fetchConfig = async () => {
            const result = await getDataSec(`${baseURL}/config`);
            setLoading(false);
            if (result.success) {
                setConfig(result.config);
            } else {
                setError(result.message);
                toast.error(result.message);
            }
        };
        fetchConfig();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig((prevConfig) => ({
            ...prevConfig,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Update config via API
        postDataSec(`${baseURL}/config`, config)
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleArrayDataChange = (
        arrayKey,
        arrayElementIndex,
        arrayElementKey,
        text
    ) => {
        setConfig((prevConfig) => {
            const prevConfigArray = prevConfig[arrayKey];
            const updatedArrayElement = {
                ...prevConfigArray[arrayElementIndex],
                [arrayElementKey]: text,
            };
            const updatedArray = prevConfigArray.map((item, i) =>
                i === arrayElementIndex ? updatedArrayElement : item
            );
            console.log(updatedArray);
            return {
                ...prevConfig,
                [arrayKey]: updatedArray,
            };
        });
    };

    const handleFieldAddition = (arrayKey) => {
        setConfig((prevConfig) => {
            const prevConfigArray = prevConfig[arrayKey];
            const emptyObject = {};
            Object.keys(prevConfig[arrayKey][0]).forEach((key) => {
                emptyObject[key] = "";
            });
            delete emptyObject._id;
            const updatedArray = [...prevConfigArray, emptyObject];
            return {
                ...prevConfig,
                [arrayKey]: updatedArray,
            };
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div style={{ padding: 20 }}>
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    padding: 20,
                }}
            >
                <h1>Config Page</h1>
                <form onSubmit={handleSubmit}>
                    {Object.keys(config).map((key) =>
                        Array.isArray(config[key]) ? (
                            config[key].length > 0 && (
                                <div
                                    style={{
                                        border: "1px solid #444",
                                        padding: 20,
                                        borderRadius: 16,
                                    }}
                                >
                                    {config[key].map((item, index) => (
                                        <>
                                            <h1>
                                                {key} No {index + 1}
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setConfig(
                                                            (prevConfig) => {
                                                                const prevConfigArray =
                                                                    prevConfig[
                                                                        key
                                                                    ];
                                                                const updatedArray =
                                                                    prevConfigArray.filter(
                                                                        (
                                                                            item,
                                                                            i
                                                                        ) =>
                                                                            i !==
                                                                            index
                                                                    );
                                                                return {
                                                                    ...prevConfig,
                                                                    [key]: updatedArray,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </h1>
                                            {Object.keys(item).map(
                                                (nestedkey, nestedIndex) => (
                                                    <div
                                                        key={nestedIndex}
                                                        style={{
                                                            marginBottom: 10,
                                                            display: "flex",
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <label>
                                                            {nestedkey}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name={nestedkey}
                                                            style={{
                                                                marginLeft: 20,
                                                                display: "flex",
                                                                width: 300,
                                                            }}
                                                            value={
                                                                item[nestedkey]
                                                            }
                                                            onChange={(e) =>
                                                                handleArrayDataChange(
                                                                    key,
                                                                    index,
                                                                    nestedkey,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ))}
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            handleFieldAddition(key);
                                        }}
                                    >
                                        Add more Fields
                                    </Button>
                                </div>
                            )
                        ) : (
                            <div
                                key={key}
                                style={{
                                    marginBottom: 10,
                                    display: "flex",
                                    flex: 1,
                                }}
                            >
                                <label>{key}</label>
                                <input
                                    type="text"
                                    name={key}
                                    style={{
                                        marginLeft: 20,
                                        display: "flex",
                                        width: 300,
                                    }}
                                    value={config[key]}
                                    onChange={handleChange}
                                />
                            </div>
                        )
                    )}
                    <Button
                        type="submit"
                        style={{ marginTop: 20 }}
                        variant="outlined"
                    >
                        Update Config
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ConfigPage;
