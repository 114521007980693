import {
    Button,
    FormControl,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { baseURL } from "../../config/config";

const Campaign = () => {
    const [formDataState, setFormData] = useState({
        name: "",
        description: "",
        type: "",
    });

    const createCampaign = () => {
        try {
            if (
                formDataState.name === "" ||
                formDataState.description === "" ||
                formDataState.type === ""
            ) {
                alert("Please fill all the fields");
                return;
            }
            if (formData.type === "wallet") {
                if (formData.amount === "") {
                    alert("Please fill all the fields");
                    return;
                }
            }
            const file = document.getElementById("customerCsvFile").files[0];
            if (!file) {
                alert("Please upload a file");
                return;
            }
            const formData = new FormData();
            formData.append("name", formDataState.name);
            formData.append("description", formDataState.description);
            formData.append("type", formDataState.type);
            formData.append("file", file);
            if (formData.type === "wallet") {
                formData.append("amount", formDataState.amount);
            }
            fetch(`${baseURL}/campaigns`, {
                method: "POST",
                body: formData,
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        alert("Campaign created successfully");
                    } else {
                        alert("Failed to create campaign");
                    }
                });
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div
            style={{
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "20px",
                margin: "20px",
                flexDirection: "column",
                flex: 1,
            }}
        >
            <h1>Campaigns</h1>
            <div style={{ gap: 10, display: "flex", alignItems: "center" }}>
                <FormGroup style={{ gap: 20 }}>
                    <FormControl>
                        <InputLabel>Campaign Name</InputLabel>
                        <Input name="name" />
                    </FormControl>
                    <FormControl>
                        <InputLabel>description</InputLabel>
                        <Input name="description" />
                    </FormControl>
                    <FormControl>
                        <Select
                            onChange={(e) => {
                                console.log(e);
                                setFormData({
                                    ...formDataState,
                                    type: e.target.value,
                                });
                            }}
                            label="Type"
                            value={"wallet"}
                        >
                            <MenuItem value="wallet">Wallet</MenuItem>
                        </Select>
                    </FormControl>
                    {formDataState.type === "wallet" ? (
                        <FormControl>
                            <InputLabel>Amount</InputLabel>
                            <Input name="amount" />
                        </FormControl>
                    ) : (
                        <></>
                    )}
                    <FormGroup>
                        <InputLabel>
                            Upload csv with column "phoneNo"
                        </InputLabel>
                        <Input
                            disableUnderline
                            id="customerCsvFile"
                            type="file"
                            name="file"
                        />
                    </FormGroup>
                    <Button onClick={createCampaign}>Create Campaign</Button>
                </FormGroup>
            </div>
        </div>
    );
};

export default Campaign;
