import React, { useEffect, useState } from "react";
import "./Category.css";
import { toastError, toastSuccess } from "../../utils/toast";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseURL, liveURL, token } from "../../config/config";
import axios from "axios";

const Category = () => {
    const [tree, setTree] = useState<any>(null);
    const [selectedCategoryID, setSelectedCategoryID] = useState<number | null>(null);
    const [selectedSubCategoryID, setSelectedSubCategoryID] = useState<number | null>(null);
    const [selectedLeafCategoryID, setSelectedLeafCategoryID] = useState<number | null>(null);

    const [categoryItem, setCategoryItem] = useState<any>(null);
    const [subCategoryItem, setSubCategoryItem] = useState<any>(null);
    const [toEditCategory, setToEditCategory] = useState<any>(null);
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = useState(false);

    //   useEffect(() => {
    //     let perm = localStorage.getItem("permissions");
    //     if (perm) {
    //       perm = JSON.parse(perm);
    //       if (perm.some(permission => permission.name === 'category' && permission.read === true || permission.name === "admin")) {
    //         if (perm.some(permission => permission.name === 'category' && permission.modify === true || permission.name === "admin"))
    //           setDisableButtons(false);
    //         else
    //           setDisableButtons(true);
    //       } else {
    //         navigate("/404")
    //       }
    //     }
    //   }, [])

    const getCategories = async () => {
        try {
            const result = await axios.get(`${baseURL}/category/tree/v2`);
            if (result.data.success) {
                setTree(result.data.category);
                // setSelectedCategoryID(0);
                // setCategoryItem(result.data.category[0].children[0]);
                //   setSelectedSubCategoryID(result.category[0].children[0]._id);
                //   setSubCategoryItem(result.category[0].children[0]);
            } else {
                console.log("cannot fetch categories", result.data.message);
            }
        } catch (err) {
            console.log("cannot fetch categories", err);
        }
    };
    useEffect(() => {
        getCategories().then();
    }, []);

    const handleCategoryId = (index) => {
        setToEditCategory(tree[index]);
        setCategoryItem(tree[index]);
        // setSelectedSubCategoryID(0);
        // setSubCategoryItem(tree[index].children[0]);

        setSelectedCategoryID(index);
    };
    const getLatestCategories = async () => {
        try {
            setSubCategoryItem(null);
            setCategoryItem(null);
            const result = await axios.get(`${baseURL}/category/tree/v2`);
            if (result.data.success) {

            }
        } catch (err) {
            console.log("cannot fetch categories", err);
        }
    };

    const handleSubCategoryId = (index) => {
        setToEditCategory(tree[selectedCategoryID].children[index]);
        setSubCategoryItem(tree[selectedCategoryID].children[index]);
        setSelectedSubCategoryID(index);
        setSelectedLeafCategoryID(null);
    };

    const handleLeafcategoryId = (item, itemId) => {
        setToEditCategory(tree[selectedCategoryID].children[selectedSubCategoryID].children[item])
        setSelectedLeafCategoryID(itemId);
    }

    const updateCategory = async (item) => {
        try {
            const result = await axios.put(`${baseURL}/category/update`, item, { "headers": { "x-access-token": token } });
            if (result.data.success) {
                toastSuccess("Category Updated Successfully");
                getLatestCategories().then();
            } else {
                toastError(result.data.message);
            }
        } catch (err) {
            toastError("Cannot Update Category");
        } finally {
            setToEditCategory(prev => prev.tempCategoryBanners ? ({ ...prev, tempCategoryBanners: [] }) : prev)
        }
    }

    const renderCategories = () => {
        return (
            <div className="buttonContainer">
                {tree.map((item, index) => {
                    return (
                        <button className={`categoryProductButton ${index === selectedCategoryID ? "active" : ""}`} onClick={() => handleCategoryId(index)} key={index}>
                            {item.name}
                        </button>
                    );
                })}
            </div>
        );
    };
    const renderSubCategories = () => {
        return (
            <div>
                <button className={`subCategoryProductButton active`} style={{ fontSize: 20, borderBottom: 1, borderColor: 'solid black' }}>
                    Sub Categories
                </button>
                {categoryItem.children.map((item, index) => {
                    return (
                        <button className={`subCategoryProductButton ${index === selectedSubCategoryID ? "active" : ""}`} onClick={() => handleSubCategoryId(index)} key={index}>
                            {item.name}
                        </button>
                    );
                })}
            </div>
        )
    };

    const renderLeafCategories = () => {
        return (
            <div className="leafCategoryContainer">
                {subCategoryItem.children.map((item, index) => {
                    return (
                        <button onClick={() => { handleLeafcategoryId(index, item.id) }} key={index} className={`leafCategoryItem ${selectedLeafCategoryID === item.id ? "active" : ""}`}>
                            <label>{item.name}</label>
                        </button>
                    );
                })}
            </div>
        );
    };

    const handleCheckBoxChange = async (item) => {
        // if (item.isActive) {
        //   const result = await deleteCategory(item._id);
        //   if (result.success) {
        //     toast.success("Deactivated Category: " + item.name);
        //   } else {
        //     toast.error(result.message);
        //   }
        // } else {
        //   const result = await activateCategory(item._id);
        //   if (result.success) {
        //     toast.success("Activated Category: " + item.name);
        //   } else {
        //     toast.error(result.message);
        //   }
        // }
        // getLatestCategories().then();
        // getLatestSubCategories().then();
    };

    const uploadImageBtn = async (id, index) => {
        const file: any = document.getElementById(id) as HTMLInputElement;
        const formData = new FormData();
        formData.append("image", file?.files[0]);
        axios.put(`${baseURL}/upload`, formData, {
            headers: {
                "x-access-token": localStorage.getItem("token") || "",
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            toast.success("Image Uploaded Successfully")
            let temp: any = { ...toEditCategory };
            if (!temp?.tempCategoryBanners)
                temp.tempCategoryBanners = [];

            temp.categoryBanners[index].imgUrl = res.data.path.filename;
            temp.tempCategoryBanners.push(res.data.path.filename);
            console.log(temp);
            setToEditCategory(temp);
        })
            .catch((err) => {
                console.log(err);
            });

    }

    const removeBanner = (id) => {
        let temp = toEditCategory;
        temp.banners.splice(id, 1);
        setToEditCategory(temp);
    }

    useEffect(() => {
        console.log(toEditCategory)
    }, [toEditCategory])

    const RenderToEdit = () => {
        if (toEditCategory === null) {
            return null;
        }
        return (
            <div id="selectedCategoryContainer">
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: 8 }}>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: "0px 20px" }}>
                        <h2>Category Details</h2>
                        <button
                            className="SaveBtn"
                            style={{ width: 70, height: 40, margin: 10 }}
                            onClick={() => { updateCategory(toEditCategory) }}
                        >Save</button>
                    </div>
                    <div className="editBoxes">
                        <p>Category Id</p>
                        <input value={toEditCategory._id} disabled />
                    </div>
                    <div className="editBoxes">
                        <p>Category Name</p>
                        <input value={toEditCategory.name} onChange={(e) => {
                            setToEditCategory(prev => ({ ...prev, name: e.target.value }))
                        }} />
                    </div>
                    <div className="editBoxes">
                        <p>Url Key</p>
                        <input value={toEditCategory.urlKey} onChange={(e) => { setToEditCategory(prev => ({ ...prev, urlKey: e.target.value })) }} />
                    </div>
                    <div className="editBoxes">
                        <p>Offer Text</p>
                        <input value={toEditCategory.offertext} onChange={(e) => { setToEditCategory(prev => ({ ...prev, offertext: e.target.value })) }} />
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: 8 }}>
                    <div className="editBoxes">
                        <p>MetaKeywords</p>
                        <input value={toEditCategory?.seo?.metaKeywords} onChange={(e) => { setToEditCategory(prev => ({ ...prev, seo: { ...prev.seo, metaKeywords: e.target.value } })) }} />
                    </div>
                    <div className="editBoxes">
                        <p>MetaTitle</p>
                        <input value={toEditCategory?.seo?.metaTitle} onChange={(e) => { setToEditCategory(prev => ({ ...prev, seo: { ...prev.seo, metaTitle: e.target.value } })) }} />
                    </div>
                    <div className="editBoxes">
                        <p>Meta Description</p>
                        <input value={toEditCategory?.seo?.metaDescription} onChange={(e) => { setToEditCategory(prev => ({ ...prev, seo: { ...prev.seo, metaDescription: e.target.value } })) }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: 8 }}>
                    <div className="editBoxes">
                        <p>Image</p>
                        {toEditCategory?.images &&
                            <img src={`${liveURL}/public/cat/${toEditCategory.id}/${toEditCategory?.images[0]}`} alt="category" style={{ width: 100, height: 100 }} />}
                        <input type="file" />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: 8 }}>
                    <div style={{ display: 'flex', flex: 1, margin: 20, justifyContent: 'space-between' }}>

                        <p>Category Banners</p>
                        <button onClick={() => setToEditCategory(prev => {
                            if (!prev.categoryBanners)
                                return ({ ...prev, categoryBanners: [{ imgUrl: '', productId: '', keyword: '', productUrl: '', type: '' }] })
                            else
                                return ({ ...prev, categoryBanners: [...prev?.categoryBanners, { imgUrl: '', productId: '', keyword: '', productUrl: '', type: '' }] })
                        })}>
                            Add Image
                        </button>
                    </div>
                    {toEditCategory?.categoryBanners?.map((banner, index) => (
                        <div
                            className="AddCardDiv"
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #ccc",
                                padding: 20,
                                borderRadius: 12,
                            }}
                        >
                            {/* <img src={``}/> */}
                            <div className="inputDiv" style={{ justifyContent: 'space-between' }}>
                                <select value={banner?.type} onChange={(e) => {
                                    const newImages = [...toEditCategory.categoryBanners];
                                    newImages[index] = {
                                        ...newImages[index],
                                        type: e.target.value,
                                    };
                                    setToEditCategory((prev) => ({
                                        ...prev,
                                        categoryBanners: newImages,
                                    }));
                                }}>
                                    <option value="category">Category</option>
                                    <option value="product">Product</option>
                                    <option value="tag">Tag</option>
                                </select>
                                <button
                                    onClick={() => {
                                        const temp = [
                                            ...toEditCategory.categoryBanners.slice(0, index),
                                            ...toEditCategory.categoryBanners.slice(index + 1)
                                        ];
                                        setToEditCategory((prev) => ({ ...prev, categoryBanners: temp }));
                                    }}
                                    style={{
                                        borderRadius: 12,
                                        maxWidth: '100px',
                                        padding: "5px 10px",
                                        border: 0,
                                        backgroundColor: 'rgb(244, 96, 96)',
                                        color: 'white'
                                    }}>Delete</button>
                            </div>

                            <img src={`${baseURL}/public/banner/${banner.imgUrl}`} style={{ height: 100, objectFit: 'contain' }} />
                            <div className="inputDiv" >
                                <label>Image URL</label>
                                <input type="file" id={`${banner._id}`} placeholder={banner.imgUrl} />
                                <button style={{ padding: '10px 20px', borderRadius: 12, backgroundColor: '#eee', border: 0 }} onClick={() => { uploadImageBtn(banner._id, index) }} >Upload</button>
                            </div>
                            <div className="inputDiv" >
                                <label>ID</label>
                                <input
                                    required
                                    type="text"
                                    placeholder={`Enter ${banner.type || 'category'} Id`}
                                    value={banner.productId || ""}
                                    onChange={(e) => {
                                        const newImages = [...toEditCategory.categoryBanners];
                                        console.log("newImages", newImages)
                                        newImages[index] = {
                                            ...newImages[index],
                                            productId: e.target.value,
                                        };
                                        console.log("newImages", newImages)
                                        setToEditCategory((prev) => ({
                                            ...prev,
                                            categoryBanners: newImages,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="inputDiv" >
                                <label>Keyword</label>
                                <input
                                    required
                                    type="text"
                                    placeholder="Enter keyword"
                                    value={banner.keyword || ""}
                                    onChange={(e) => {
                                        const newImages = [...toEditCategory.categoryBanners];
                                        newImages[index] = {
                                            ...newImages[index],
                                            keyword: e.target.value,
                                        };
                                        setToEditCategory((prev) => ({
                                            ...prev,
                                            categoryBanners: newImages,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="inputDiv" >
                                <label>Url</label>
                                <input
                                    required
                                    type="text"
                                    placeholder="Enter product Url"
                                    value={banner.productUrl || ""}
                                    onChange={(e) => {
                                        const newImages = [...toEditCategory.categoryBanners];
                                        newImages[index] = {
                                            ...newImages[index],
                                            productUrl: e.target.value,
                                        };
                                        setToEditCategory((prev) => ({
                                            ...prev,
                                            categoryBanners: newImages,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    ))}

                    {/* <img src={toEditCategory.bannerImages} alt="category" style={{ width: 100, height: 100 }} /> */}
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flex: 1, margin: 20, gap: 20 }}>
            <div id="categoryRootContainer">
                <div>
                    <h2>Select Category</h2>
                </div>
                <span></span>
                <div>
                    {tree !== null ? renderCategories() : <></>}
                </div>
                {
                    selectedCategoryID !== null &&
                    <div id="subCategoryContainer">
                        <div id="subCategoryList">{categoryItem ? renderSubCategories() : <></>}</div>
                        <div id="leafCategoryList">
                            <div style={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
                                <span>{subCategoryItem?.name}</span>
                            </div>
                            {subCategoryItem ? renderLeafCategories() : <></>}
                        </div>
                    </div>}
            </div>

            {/* <div style={{display: 'flex', flex: 0.5 , backgroundColor: 'white'}}> */}
            {toEditCategory !== null ? RenderToEdit() : <div style={{ flex: 1, backgroundColor: 'white', padding: 20, borderRadius: 8 }}>
                <h2>Select a category to view</h2></div>}
            {/* </div> */}
        </div>
    );
};

export default Category;