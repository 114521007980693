import { useEffect, useState } from "react"
import { ProductCard } from "./ProductCard.tsx";
import React from "react";

const AllProductList = ({ page, setPage, loadingProducts, products, noMoreProducts, disablebuttons, setSelectedProductId }) => {

    useEffect(() => {
        function handleScroll() {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && !loadingProducts)
                setPage(prevPage => prevPage + 1);
            return;
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadingProducts]);


    const renderProduct = () => {
        return (
            products.map((item, index) => {
                return (
                    <ProductCard item={item} index={index} disablebuttons={disablebuttons} setSelectedProductId={setSelectedProductId} />)
            })
        )
    }

    return (
        <div id="ProductListContainer">
            <div style={{ display: "flex", justifyContent: "space-between", flex: 1, height: 50 }}>
                <h3>All Products</h3>
            </div>
            <div id="productList">
                <table style={{ fontSize: 16 }} >
                    <thead className="headerRow">
                        <tr>
                            <th>Item/SKU Code</th>
                            <th>Product Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderProduct()}
                    </tbody>
                </table>
                {noMoreProducts && <p style={{ textAlign: 'center' }}>❌No More Orders</p>}
                {loadingProducts && <div className="loader"></div>}
            </div>
        </div>
    )
}
export default AllProductList